import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "One Day Before",
  "date": "2015-07-21T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you’ve been following my Twitter feed for the last couple of weeks, you’ve probably noticed that I’ve been working on a talk. The event where I’m giving said talk is tomorrow.`}</p>
    <h2>{`Be uncomfortable`}</h2>
    <p>{`I decided I was going to commit to participate to this event to get out of my comfort zone. Heck, it still feels kind of uncomfortable. Things could still go horribly wrong tomorrow morning. It’s terrifying, but at the same time so exciting. I’ll be doing something for the first time. I have a big passion for sharing what I do with other people and I can’t wait to talk about all these things I’ve been thinking about for a long time.`}</p>
    <h2>{`It’s a lot of work`}</h2>
    <p>{`Being in the spotlight is a lot of work. We often look to conference speakers with envy. All these people are here to hear what `}<em parentName="p">{`they`}</em>{` have to say. But when you flip that over and you’re the one who agreed to do this, what do you make of that attention coming your way? What’s the message you want to get across?`}</p>
    <p>{`I can’t wait for tomorrow.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      